import styled, { css } from 'styled-components'
import { width, height, space, fontSize } from 'styled-system'
import mq from 'styles/mq'

import { color, font } from 'styles/variables'

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  line-height: 2.6;
  background: ${color.primary};
  border-radius: 30px;
  text-decoration: none;
  color: ${props => (props.color ? props.color : color.buttonTextColor)};
  font-family: ${font.customFont};
  font-size: 1.8rem;
  font-weight: ${font.semiBold};
  transition: all 0.3s ease;
  border: 1px solid transparent;
  text-align: center;
  height: 50px;
  display: inline-block;

  &:hover {
    background: ${color.darkerPrimary};
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    background: ${color.gray};
  }

  ${props =>
    props.secondary &&
    css`
      background: #7d8d9e;
      border-radius: 4px;
      font-size: 1.4rem;
      line-height: 32px;
      height: 34px;
      padding: 0 12px;
      width: auto;

      &:hover {
        background: #9db1c6;
      }
    `};

  ${mq.tablet`
    min-width: 140px;
    padding: 0 20px;
  `};

  ${width};
  ${height};
  ${space};
  ${fontSize};
`
