import styled, { css } from 'styled-components'
import {
  fontSize,
  space,
  color,
  textAlign,
  flex,
  alignSelf,
  lineHeight,
  width,
  maxWidth,
  fontWeight,
} from 'styled-system'

import { color as colors, font } from 'styles/variables'
import mq from 'styles/mq'

import { linkStyles } from 'components/buttons/LinkButton/styled'

const defaultFontSize = props => {
  if (props.large) return '1.8rem'
  if (props.small) return '1.4rem'
  if (props.extraSmall) return '1.2rem'
  return '1.6rem'
}

export const Text = styled.div`
  font-family: ${font.customFamily};
  font-size: ${defaultFontSize};
  color: ${colors.base};
  font-weight: ${props => (props.medium ? font.medium : font.normal)};
  ${props =>
    props.ellipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    `};
  ${fontSize};
  ${space};
  ${color};
  ${textAlign};
  ${flex};
  ${alignSelf};
  ${lineHeight};
  ${width};
  ${maxWidth};
  ${fontWeight};

  ${props => (props.underline ? 'text-decoration: underline;' : '')}
  & strong {
    font-weight: ${font.semiBold};
  }

  & a {
    ${linkStyles};
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &,
      &::after {
        border-color: ${color.neutral4};
        background: #fff;
      }
    `}
`

export const TextHighlight = styled(Text)`
  display: inline-block;
  color: ${colors.blue};
  font-weight: ${font.medium};
`

export const TextBold = styled(Text)`
  font-weight: ${font.extraBold};
  font-size: 1.8rem;
  ${fontSize};
`

export const TextSemi = styled(Text)`
  font-weight: ${font.semiBold};
  font-size: 1.8rem;
  ${fontSize};
  ${space};
`

export const Heading = styled(Text)`
  font-weight: ${font.medium};
  font-size: 2.2rem;
  ${mq.tablet`
    font-size: 2.5rem;
  `};
  ${textAlign}
`

export const PageTitle = styled(Text)`
  font-weight: ${font.medium};
  font-size: 3rem;
  ${mq.tablet`
    font-size: 3.5rem;
  `};
  text-align: center;
  background: ${colors.transparent};
`

export const HeadingBold = styled(TextBold)`
  font-size: 2.4rem;
`

export const TextSecondary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
`
//  text-decoration-thickness: 2px;

export const TextLineThrough = styled(Text)`
  position: relative;
  text-decoration: line-through;
  text-decoration-color: ${colors.primary};
  ${// eslint-disable-next-line property-no-unknown
  `text-decoration-thickness: 2px;`};
`

export const TextBanner = styled(Text)`
  border: 1px solid ${colors.error};
  padding: 15px;
  margin-bottom: 50px;
  box-shadow: 1px 2px 2px 1px lightgrey;
  border-radius: 10px;
`

export const TextSecondarySummary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
  margin-top: 4px;
`
